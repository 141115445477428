<template>
  <div class="upload-dom">
      <div class="van-cell"> 
        <div>
          <p class="text-title">{{label}}</p>
          <van-uploader :disabled='disabled' :deletable='!disabled' v-model="fileList" :max-count="maxCount" :after-read="afterRead" @delete='beforeDelete' :class="{'disabledClass':disabled}"/>
        </div>
      </div>
  </div> 
</template>
<script>
/**
 * maxCount 最大上传几张
 * label：标题
 * required:是否必填
 */
import API from "../../api/http";
import { mapGetters } from "vuex";
import utils from '@/utils'
export default {
  props: {
    maxCount:{
      type: Number,
      default: 1,
    },
    label: {
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    value:{
       type: String,
       default:''
    },
    importType:{
        type:String,//图片的类型
        default:''
    },
    disabled:{
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters("user", {
      clientCode: "clientCode"
    })
  },
  data() {
    return {
      fileList: [
        // { url: 'https://img.yzcdn.cn/vant/leaf.jpg' },
      ],
    };
  },
  watch: {
  },
  created() {
    this.$nextTick(()=>{
      this.initData()
      console.log(this.disabled);
    })
  },
  methods: {
    initData(){
      let val = this.value || ''
      this.fileList = val === '' ? [] : [{
          url : utils.getImgUrl() + val
        }]
    },
    afterRead(file) {
      //上传图片
      var itemType =  `template-${this.importType}`
			const files = file.file
			const fileFormData = new FormData()
			fileFormData.append('file', files)
			fileFormData.append('clientCode', this.clientCode)
			fileFormData.append('importType', itemType)
      
			API.ssWorkingServicePolicyUploadMultipartFile(fileFormData).then(res => {
				if (res.status === 200) {
          // console.log(res);
          this.$emit("update:value", res.message);
				}
			})
    },
    beforeDelete(file){
      //删除
      this.$emit("update:value", '');
    }
  },
};
</script>
<style lang='scss' scoped>
.upload-dom{
    position: relative;
    z-index: 3;
    .text-title{
      text-align: center;
      font-size: 11px;
      font-weight: 400;
      color: #262626;
      line-height: 16px;
      margin-bottom: 10px;
    }
    /deep/{
      .van-uploader{
        width: 100%;
        .van-uploader__upload,.van-uploader__preview,.van-uploader__preview-image{
          width: 100px;
          height: 70px;
        }
        .van-uploader__upload{
          background: none;
          border-radius: 4px;
          border: 1px dashed #D9D9D9;
        }
        .van-uploader__preview-delete{
          position: absolute;
          top: -6px;
          right: -6px;
          color: #fff;
          -webkit-transform: scale(1);
          transform: scale(1);
          background: #C0C0C0;
          border-radius: 50%;
          text-align: center;
          border: 3px solid #C0C0C0;
          i{
            -webkit-transform: scale(1);
            transform: scale(1);
            font-size: 18px;
          }
        }
      }
      .disabledClass .van-uploader__upload-icon{
        display: none;
      }
    }
}

</style>